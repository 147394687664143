$playfairdisplay-font-path: "/assets/fonts/playfair_display/" !default;


@font-face {
    font-family: "Playfair Display";
    src: url("#{$playfairdisplay-font-path}PlayfairDisplay-VariableFont_wght.ttf") format("truetype");
}

@font-face {
    font-family: "Playfair Display Italic";
    src: url("#{$playfairdisplay-font-path}PlayfairDisplay-Italic-VariableFont_wght.ttf") format("truetype");
}

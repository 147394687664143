._icon-button {
  //gt rid of text selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
 // box-sizing: border-box;
  // font
  vertical-align: top;
  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 46px;
  position: relative;

  i {
    font-size: 24px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  // for appbars needing badged icon
  .badge {
    position: absolute;
    top: -14px;
    left: 24px;
    z-index: 1;

    &.small {
      top: -16px;
      left: 26px;
    }
  }
}

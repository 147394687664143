
.grid-collection-list {
	list-style: 	none;
	padding:		0;
	margin:			0;
	-webkit-box-sizing: border-box;
	box-sizing: 		border-box;
}


.grid-collection-element {
	display:			inline-block;
}


.grid-element {
	margin-top:			4px;
	margin-left:		4px;
	display:			inline-block;
	position:			relative;

	&:firstchild {
		margin-left: 0;
	}
}

.sample-grid-element {
    @extend .grid-element;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding-top: 6px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,0.2);
    box-sizing: 		border-box;
}
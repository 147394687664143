._blog-page-m3 {
    padding-top: 72px;
    font-size: 20px;

    .heading {
        @extend ._display-large;
        margin-bottom: 56px;
    }

    .subheading {
        @extend ._headline-large;
        margin-bottom: 56px;
    }

    .header-image {
        width: 100%;
        margin-bottom: 56px;

        img {
            width: 100%;
            height: auto;
            object-fit: contain;    
        }

        .caption {
            margin-top: 8px;
            text-align: center;
            font-style: italic;
            @extend ._body-medium;
            
        }
    }
}


body {
  overscroll-behavior-y: none;
  position: fixed;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  
  min-height: 100vh;
  min-height: -webkit-fill-available; //ios 100vh fix

  touch-action: manipulation;
  padding-bottom: env(safe-area-inset-bottom); // for absolute bottom
}

html {
  height: -webkit-fill-available;
}

.hidden {
  visibility: hidden;
}

////////////////////////////
///  Styles
/// 
////////////////////////////

/* Shape */
@import './style/shape/shape.scss';


/* Motion */
@import './style/motion/motion.scss';
@import './style/motion/button-press-animation.scss';


/* Typography */
@import './style/typography/typography.scss';

/* Layout */
@import './layout/layout.scss';

/* Containers */
@import './containers/application/application-container.scss';
@import './containers/cards/card.scss';
@import './containers/scrim/scrim.scss';
@import './containers/slider-sheet/slider-sheets.scss';
@import './containers/modal/modals.scss';

/* Grid Collection */
@import './components/grid-collection/grid-collection.scss';

/* Appbar Top */
@import './components/navigation/appbar-top/appbar-top.scss';
@import './components/navigation/navigation-rail/navigation-rail.scss';
@import './components/navigation/navigation-icon/navigation-icon.scss';
@import './components/navigation/navigation-bar/navigation-bar.scss';
@import './components/navigation/navigation-drawer/navigation-drawer.scss';

/* Avatar */
@import './components/avatar/_avatar.scss';

/* Badges */
@import './components/badges/badges.scss';

/* Chips */
@import './components/chips/chips.scss';

/* Menu */
@import './components/menu/menu.scss';
@import './components/menu/collapsible.scss';
@import './components/navigation/navigation-drawer-menu/navigation-menu.scss';

/* Progress Bars */
@import './components/progress/progress-bar/progress-bar.scss';
@import './components/progress/preloader.scss';


/* Slider Sheet */
@import './components/side-sheet/side-sheet.scss';

/* Switch */
@import './components/switch/switch.scss';

/* Tables */
@import './components/table/table.scss';

/* Text Inputs */
@import './components/text-input/input/text-input-fields.scss';

/* Color Animations */
@import './scss/animation/background-color-animation.scss';
@import './scss/animation/color-animation.scss';
@import './scss/animation/opacity-animation.scss';


/* Shadow */
@import './style/shadow/shadows.scss';



/* Grids */
@import './layout/grids/grid-12-column.scss';

@import './layout/containers.scss';



/* Buttons */
@import './components/buttons/button-std.scss';
@import './components/buttons/fab-button.scss';
@import './components/buttons/icon-button.scss';

/* Search bar */
@import './components/navigation/search/search-bar.scss';
@import './components/navigation/search/search-bar-container.scss';

/* Tabs */
@import './components/tabs/tabs.scss';


/* Vertical Lists */
@import './components/tiles-vertical/lists-vertical.scss';
@import './components/tiles-vertical/_single-item-list-text-only.scss';
@import './components/tiles-vertical/_single-item-list-icon-text.scss';
@import './components/tiles-vertical/single-item-list-leading-image.scss';
@import './components/tiles-vertical/two-item-list-text-only.scss';
@import './components/tiles-vertical/two-item-list-icon-text.scss';
@import './components/tiles-vertical/two-item-list-ileading-image.scss';
@import './components/tiles-vertical/three-item-list-text-only.scss';
@import './components/tiles-vertical/three-item-list-icon-text.scss';
@import './components/tiles-vertical/three-item-list-leading-image.scss';

/* Icons */
@import './components/icons/materialdesign/materialdesignicons';
@import './components/icons/icons-material-design.scss';
@import './components/icons/mdicon.scss';

/* Text Divider */
@import './components/text-divider/text-divider.scss';


/* Wireframing */
@import './scss/wireframing/wireframing.scss';




/**** To be updated ****/
@import './components/text-input/input/conventional-input-fields.scss';




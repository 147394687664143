.preloader-spinner {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 3px solid rgba(0,0,0,0);
    border-top:3px solid #f25a41;
    border-radius: 100%;
    animation: preloader-spinner-spin 1000ms infinite ease-in-out;
}

@keyframes preloader-spinner-spin {
    from{
        transform: rotate(0deg);
    }to{
        transform: rotate(360deg);
    }
}



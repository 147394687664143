

._animated-collection-tile {
  width:    100%;
  height:   100%;
  position: absolute;
  top:      0;
  left:     0;
  z-index:    1;
  overflow:   hidden;

  &.select-mode {
    filter:       brightness(0.7);
    transform:    translateZ(0);
    @extend       .wobblyEffectTiny;
  }

  &.selected {
    filter:       brightness(0.4);
    transform:    translateZ(0);
    transition: all 25ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
  }

}



.wobblyEffect {
  animation: wobbleMotionEffect 750ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
}

@keyframes wobbleMotionEffect {
  0% {
    transform: scale(1.3);
  }

  25% {
    transform: rotate(-15deg);
  }

  50% {
    transform: rotate(15deg);
  }

  75% {
    transform: rotate(-15deg);
  }

  100% {
    transform: rotate(0) scale(1);
  }
}


.wobblyEffectSmall {
  animation: wobbleMotionEffect 450ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
}

@keyframes wobbleMotionEffect {
  0% {
    transform: scale(1.02);
  }

  25% {
    transform: rotate(-3deg);
  }

  50% {
    transform: rotate(3deg);
  }

  75% {
    transform: rotate(-3deg);
  }

  100% {
    transform: rotate(0) scale(1);
  }
}

.wobblyEffectTiny {
  animation: wobbleMotionEffect 200ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
}

@keyframes wobbleMotionEffect {
  0% {
    transform: scale(1.01);
  }

  25% {
    transform: rotate(-0.5deg);
  }

  50% {
    transform: rotate(0.5deg);
  }

  75% {
    transform: rotate(-0.5deg);
  }

  100% {
    transform: rotate(0) scale(1);
  }
}


@-webkit-keyframes transitionZoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes _tileZoomOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

}

.tileZoomOut {
  -webkit-animation-name: _tileZoomOut;
  animation-name: _tileZoomOut;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.fade-out {
  animation: _fade-out 150ms ease-in;
}

@keyframes _fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
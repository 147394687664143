._application-layout-container {
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	transition: padding $motion-duration-short-3 cubic-bezier(0.2, 0.0, 0, 1.0), background $motion-duration-long-2 cubic-bezier(0.2, 0.0, 0, 1.0);


	display: flex;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: stretch;
  
	._background-layer {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
	}

	._top {
		order: 1;
		flex: 0 0 72px;
		box-sizing: border-box;
		overflow: hidden;
		transition: all $motion-duration-medium-2 cubic-bezier(0.2, 0.0, 0, 1.0);
		/*
		width: 100%;
		height: auto;
		position: relative;
		top: 0;
		left: 0 */
	}

	._centre-wrapper {
		width: 100%;
		//height: auto;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		position: relative;

		order: 2;
		flex: 1;
		overflow: hidden;
		min-height: 0;
	

		display: flex;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: stretch;
	  
		.left-navigation {
			order: 1;
			flex: 0 0 64px;
			overflow: hidden;
			position: relative;
			box-sizing: border-box;
			height: 100vh;
			transition: all $motion-duration-medium-2 cubic-bezier(0.2, 0.0, 0, 1.0);

			&.hide {
				flex: 0 0 0px;
			}

			&.rail {
				flex: 0 0 81px;
			}

			&.full {
				flex: 0 0 320px;
			}
		}

		.main-body {
			order: 2;
			flex: 1;
			overflow: hidden;
			min-height: 0;
			box-sizing: border-box;
		}

		.right-navigation {
			order: 3;
			flex: 0 0 320px;
			overflow: hidden;
			position: relative;
			box-sizing: border-box;
			height: 100vh;
			transition: all $motion-duration-medium-2 cubic-bezier(0.2, 0.0, 0, 1.0);

			&.hide {
				flex: 0 0 0px;
			}

			&.show {
				flex: 0 0 320px;
			}

		}

		
	}

	._bottom {
		order: 3;
		flex: 0 0 80px;
		overflow: hidden;
		box-sizing: border-box;
		transition: all $motion-duration-medium-2 cubic-bezier(0.2, 0.0, 0, 1.0);
	
		/*
		width: 100%;
		height: auto;
		position: fixed;
		bottom: 0;
		left: 0
		*/
	}	
} 	



._application-body {
	width: 100%;
	height: 100%;
	position: relative;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	transition: padding $motion-duration-short-3 cubic-bezier(0.2, 0.0, 0, 1.0), background $motion-duration-long-2 cubic-bezier(0.2, 0.0, 0, 1.0);

	display: flex;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: stretch;
  
	._inner-header {
		order: 1;
		flex: 0 0 0px;
		box-sizing: border-box;
		overflow: hidden;
		transition: all $motion-duration-medium-2 cubic-bezier(0.2, 0.0, 0, 1.0);

		&.hide {
			flex: 0 0 0px;
		}

		&.show {
			flex: 0 0 64px;
		}

	}

	._body-container {
		width: 100%;
		//height: auto;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		position: relative;

		order: 2;
		flex: 1;
		overflow: hidden;
		min-height: 0;
	
		display: flex;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: stretch;
	  
	
		._main-body {
			order: 1;
			flex: 1;
			overflow: hidden;
			min-height: 0;
			box-sizing: border-box;
		}

		._right-slider-sheet {
			order: 2;
			flex: 0 0 320px;
			overflow: hidden;
			position: relative;
			box-sizing: border-box;
			height: 100vh;
			transition: all $motion-duration-long-3 cubic-bezier(0.2, 0.0, 0, 1.0);
		//	border-top-left-radius: $radius-large;
		//	border-bottom-left-radius: $radius-large;

			&.hide {
				flex: 0 0 0px;
			}

			&.show {
				flex: 0 0 320px;
			}

		}
		
	}

} 	




._avatar-stack-5 {
    display: flex;
    list-style-type: none;
    margin: auto;
    padding:0px;
    flex-direction: row;
    float: left;

    .avatar {
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      position: relative;
      text-align: center;
      line-height: 40px;
      margin-left: -24px;
      background-color: #E0E0E0;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 2px;
      transition: all $motion-duration-medium-3 cubic-bezier(0.2, 0.0, 0, 1.0);
      transform: translateZ();
      &.open {
        margin-left: 4px;
      }
  
      &.top {
        z-index: 10;
      }

      img {
        width: 100%;
        display: block;
      }
  
      &:first-child {
        z-index: 0;
        margin-left: 0;
      }
      &:nth-child(2) {
        z-index: 1;
      }
      &:nth-child(3) {
        z-index: 2;
      }
      &:nth-child(4) {
        z-index: 3;
      }
      &:nth-child(5) {
        z-index: 4;
      }
    }
  }
  
  .avatar-stack-wrapper {
      width:              100%;
      height:             56px;
      padding-top:        4px;
      padding-bottom:     4px;
      padding-left:       0;
      padding-right:      0;
      overflow-y: auto;

      &::-webkit-scrollbar {
        height: 0px;
        background: transparent; 
    }

/*      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
  */
  }
  


  ._people-vertical-search-list {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;

    ._header {
      width: 100%;
      height: 56px;
      line-height: 56px;

      .label {
        float: left;
      }
      .control {
        float: right;
        font-size: 16px;
        font-weight: 600;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
      }
    }

    ._search-section {
      width: 100%;
      height: 80px;
      padding-top: 16px;
      padding-bottom: 8px;
      box-sizing: border-box;
    }

    ._content {
      width: 100%;
      height: 100%;
      padding-top: 16px;
      padding-bottom: 24px;  
      box-sizing: border-box;
      overflow-y: auto;

      &::-webkit-scrollbar {
        height: 0px;
        background: transparent; 
      }

    }

  }
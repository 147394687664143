$crimsontext-font-path: "/assets/fonts/crimson_text/" !default;


@font-face {
    font-family: "Crimson Text Bold";
    src: local(Crimson Text Bold), url('#{$crimsontext-font-path}CrimsonText-Bold.ttf');
    src: url("#{$crimsontext-font-path}CrimsonText-Bold.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: "Crimson Text Bold Italic";
    src: local(Crimson Text Bold Italic), url('#{$crimsontext-font-path}CrimsonText-BoldItalic.ttf');
    src: url("#{$crimsontext-font-path}CrimsonText-BoldItalic.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: "Crimson Text Italic";
    src: local(Crimson Text Italic), url('#{$crimsontext-font-path}CrimsonText-Italic.ttf');
    src: url("#{$crimsontext-font-path}CrimsonText-Italic.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "Crimson Text Regular";
    src: local(Crimson Text Regular), url('#{$crimsontext-font-path}CrimsonText-Regular.ttf');
    src: url("#{$crimsontext-font-path}CrimsonText-Regular.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "Crimson Text Semi Bold";
    src: local(Crimson Text Semi Bold), url('#{$crimsontext-font-path}CrimsonText-SemiBold.ttf');
    src: url("#{$crimsontext-font-path}CrimsonText-SemiBold.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: "Crimson Text Semi Bold Italic";
    src: local(Crimson Text Semi Bold Italic), url('#{$crimsontext-font-path}CrimsonText-SemiBoldItalic.ttf');
    src: url("#{$crimsontext-font-path}CrimsonText-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
}

.menu-container {
    list-style: none;
    box-sizing: border-box;
    transform: translateZ(0);
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    overflow: hidden;

    .divider {
      padding-left: 8px;
      padding-right: 8px;
        .divider-wraper {
          border-bottom: 1px solid #000000;
        }
    }

    &.indent {
      padding-left: 32px;
    }
}



._menu-item-no-icon {
    @extend .list-tile-item;
    height: 48px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    padding-left: 16px;
    border-radius: 24px;
    //get rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  
    .label {
      order: 1;
      flex: 1 0;
      color: #000000;
      line-height: 48px;
      display: inline-block;
      vertical-align: top;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @extend ._label-large;
    }
  
    .secondary-action {
      order: 2;
      flex: 0 0 auto;
      @extend .list-tile-secondary-action-48;
    }
}


._menu-item-w-icon {
  @extend .list-tile-item;
  height: 56px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
  border-radius: 28px;
  //get rid of text selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  .icon-wrapper {
    order: 1;
    flex: 0 0 auto;
    overflow: hidden;
    display: inline-block;
    box-sizing: border-box;
    padding-left: 16px;
    line-height: 56px;
    color: #000000;
    box-sizing: border-box;
    text-align: center;

    i {
      font-size: 20px;
      line-height: inherit;
      margin-right: 12px;
    }
  }

  .content {
    order: 2;
    flex: 1 0;
    height: 56px;
    box-sizing: border-box;
    overflow: hidden;

    .label {
      color: #000000;
      line-height: 56px;
      vertical-align: top;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @extend ._label-large;
    }
  }

  .secondary-action {
    order: 3;
    flex: 0 0 auto;
    @extend .list-tile-secondary-action-56;
  }
}


._menu-collapsible-body {
  display: none;
  box-sizing: border-box;
  padding-left: 0;
  box-sizing: border-box;

  &.show {
    display: block;
  }
  &.hide {
    display: none;
  }

  .menu-list-container {
    list-style: 		      none;
    padding-left:         24px;
  }
}

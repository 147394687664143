///////////////////////////////////////////////////
// ONE LINE LIST
///////////////////////////////////////////////////

.two-item-list-tile-icon-text {
  @extend .list-tile-item;
  height: 72px;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 16px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;

  &.inset-divider {
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
  }

  .icon-wrapper {
    order: 1;
    flex: 0 0 auto;
    overflow: hidden;
    display: inline-block;
    box-sizing: border-box;
    padding-left: 16px;
    line-height: 72px;
    color: #000000;
    box-sizing: border-box;
    text-align: center;

    i {
      font-size: 20px;
      line-height: inherit;
      margin-right: 28px;
    }
  }

  .circle-wrapper {
    order: 1;
    flex: 0 0 auto;
    padding-left: 8px;
    padding-top: 16px;
    overflow: hidden;
    display: inline-block;
    box-sizing: border-box;

    .circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;
      line-height: 40px;
      box-sizing: border-box;
      text-align: center;

      i {
        font-size: 18px;
        line-height: inherit;
      }

      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
    }
  }


  .content {
    order: 2;
    flex: 1 0;
    height: 72px;
    box-sizing: border-box;
    overflow: hidden;
    float: left;

    .top-line {
      font-size: 16px;
      color: #000000;
      line-height: 48px;
      font-weight: 600;
    }

    .bottom-line {
      font-size: 16px;
      color: #000000;
      line-height: 0;
      vertical-align: top;
      font-weight: 400;
      opacity: 0.8;
    }
  }

  .secondary-action {
    order: 3;
    flex: 0 0 auto;
    float: right;
    @extend .list-tile-secondary-action-72;
  }
}

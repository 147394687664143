$bebasneue-font-path: "/assets/fonts/bebasneue/" !default;

/*** Bebas Neue ***/
@font-face {
    font-family: 'Bebas Neue Regular';
    src: url("#{$bebasneue-font-path}bebasneue-regular-webfont.ttf") format("truetype"),
         url('#{$bebasneue-font-path}bebasneue-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$bebasneue-font-path}bebasneue-regular-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}


.bebas-neue {
  font-family: 'bebas_neueregular';
}

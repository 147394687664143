._launcher-screen {
    width: 100%;
    height: 100vh;
    position: relative;

    .logo {
        padding-top: 24px;

        img {
            width: 240px;
            height: auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .content {
        width: 100%;

        box-sizing: border-box;
    //    position: relative;
    }
}




._horizontal-swipe-row {
    height: 100%;
    padding-top: 8px;
    box-sizing: border-box;

    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    overflow-x: scroll;
    scroll-padding-left: 16px;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        height: 0px;
        background: transparent; 
    }
  
}
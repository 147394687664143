

._abstract-tile {
  position: relative;
  width:    100%;
  height:   100%;
  top:      0;
  left:     0;
  z-index:    1;
  overflow:   hidden;
  box-sizing: border-box;
  vertical-align:top;
  -webkit-tap-highlight-color: transparent;
  cursor:pointer;
  scroll-snap-align: center;
  transition: all $motion-duration-short-3 cubic-bezier(0.2, 0.0, 0, 1.0);

}


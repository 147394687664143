._navigation-icon {
  width: 80px;
  height: 56px;
  text-align: center;
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  .indicator-wrapper {
    width: 80px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 4px;
    .indicator {
      width: 56px;
      height: 32px;
      line-height: 34px;
      border-radius: 16px;
      font-size: 24px;
      box-sizing: border-box;
      //  overflow: hidden;
      position: relative;
      transition: all 250ms cubic-bezier(0.2, 0, 0, 1);

      .badge {
        position: absolute;
        top: -12px;
        left: 28px;

        &.small {
          top: -16px;
          left: 36px;
        }
      }
    }
  }

  .label {
    width: 100%;
    text-align: center;
    @extend ._label-small;
  }

}

.nav-icon-touch-animation {
  animation: nav-icon-touch-anim 150ms cubic-bezier(0.24, 0, 0.38, 1);
}

@keyframes nav-icon-touch-anim {
  from {
    transform: scale3d(1, 1, 1);
    opacity: 0.7;
  }

  30% {
    transform: scale3d(1.1, 1.1, 1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 1);
  }

  50% {
    transform: scale3d(1.05, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

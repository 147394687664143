$alegreya-font-path: "/assets/fonts/alegreya/" !default;


/*** Aileron**/
@font-face {
    font-family: 'Alegreya Regular';
    src: url("#{$alegreya-font-path}Alegreya.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

$opensans-font-path: "/assets/fonts/opensans/" !default;


@font-face {
    font-family: 'Open Sans Bold';
    src: url("#{$opensans-font-path}OpenSans-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.opensans-bold {
  font-family: 'Open Sans Bold';
}


@font-face {
    font-family: 'Open Sans Bold Italic';
    src: url("#{$opensans-font-path}OpenSans-BoldItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.opensans-bold-italic {
  font-family: 'Open Sans Bold Italic';
}


@font-face {
    font-family: 'Open Sans Extra Bold';
    src: url("#{$opensans-font-path}OpenSans-ExtraBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.opensans-extra-bold {
  font-family: 'Open Sans Extra Bold';
}


@font-face {
    font-family: 'Open Sans Extra Bold Italic';
    src: url("#{$opensans-font-path}Open Sans-ExtraBoldItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.opensans-extra-bold-italic {
  font-family: 'Open Sans Extra Bold Italic';
}




@font-face {
    font-family: 'Open Sans Italic';
    src: url("#{$opensans-font-path}OpenSans-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.opensans-italic {
  font-family: 'Open Sans Italic';
}


@font-face {
    font-family: 'Open Sans Light';
    src: url("#{$opensans-font-path}OpenSans-Light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.opensans-light {
  font-family: 'Open Sans Light';
}

@font-face {
    font-family: 'Open Sans Light Italic';
    src: url("#{$opensans-font-path}OpenSans-LightItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.opensans-light-italic {
  font-family: 'Open Sans Light Italic';
}



@font-face {
    font-family: 'Open Sans Regular';
    src: url("#{$opensans-font-path}OpenSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.opensans-regular {
  font-family: 'Open Sans Regular';
}


@font-face {
    font-family: 'Open Sans Semi Bold';
    src: url("#{$opensans-font-path}OpenSans-SemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.opensans-semibold {
  font-family: 'Open Sans Semi Bold';
}


@font-face {
    font-family: 'Open Sans Semi Bold Italic';
    src: url("#{$opensans-font-path}OpenSans-SemiBoldItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.opensans-semibold-italic {
  font-family: 'Open Sans Semi Bold Italic';
}

._signin-flow-container {
    overflow: hidden;
    box-sizing:border-box;
    width: 100%;
    height: 100%;
    position: relative;

	display: flex;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: stretch;

    padding-left: 24px;
    padding-right: 24px;

    .header {
		order: 1;
		flex: 0 0 64px;
		box-sizing: border-box;
		overflow: hidden;
        width: 100%;
        text-align: center;
        padding-top: 16px;
        font-weight: 500;
    }

    .body {
        padding-top: 24px;
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px; 
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		position: relative;

		order: 2;
		flex: 1;
		overflow: hidden;
        text-align: center;
    }

    .action-wrapper {
		order: 3;
		flex: 0 0 80px;
		overflow: hidden;
		box-sizing: border-box;
        padding-top: 16px;
    }

}

._background-sunrise {
  position: relative;
  -webkit-transition: all 1000ms cubic-bezier(0.53, 0.01, 0.36, 1.63);

  .sun {
    width:            600px;
    height:           600px;
    border-radius:    50%;
    filter:           blur(50px);
    opacity:          1;
    position:         absolute;
    transform:        translateZ(0);  //force the GPU to render
    top:              -320px;
    right:            -300px;
    background-color: #FFFFFF;
    -webkit-transition: all 750ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
  }

  .solar-corona {
    width:            1450px;
    height:           1400px;
    border-radius:    50%;
    filter:           blur(70px);
    opacity:          1;
    position:         absolute;
    transform:        translateZ(0);  //force the GPU to render
    top:              -1000px;
    right:            -800px;
    background-color: #FFB300;
    -webkit-transition: all 750ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
  }


  .upper-sky {
    width:            100%;
    height:           140px;
    border-radius:    50%;
    filter:           blur(130px);
    opacity:          1;
    position:         absolute;
    transform:        translateZ(0);  //force the GPU to render
    background-color: #E91E63;
    top:  300px;
    -webkit-transition: all 750ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
  }

  .lower-sky {
    bottom:           0;
    left:             0;
    position:         absolute;
    width:            100%;
    height:           64px;
    filter:           blur(64px);
    opacity:          1;
    transform:        translateZ(0);  //force the GPU to render
    background-color: #E91E63;
    -webkit-transition: all 750ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
  }

}


.std-social-button {
  border: none;
  border-radius: 28px;
  display: inline-block;
  position: relative;

//  display:          flex;
  flex-wrap:        nowrap;
  flex-direction:   row;
  align-items:      stretch;
  overflow:         hidden;
  position:         relative;
  box-sizing:       border-box;
  -webkit-box-sizing: border-box;

  padding-left: 24px;
  //padding-right: 16px;

  width: 100%;
  height: 56px;
  line-height: 56px;
  color: #ffffff;
  white-space: nowrap;
  font-family: Arial, Helvetica, sans-serif;

  //get rid of text selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  box-sizing: border-box;

  // font
  vertical-align: top;

  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;

  cursor: pointer;

  .icon {
    flex: 0 0 auto;
    order: 1;
    float: left;

    i {
      font-size: 28px;
      line-height: 56px;
    }
  
  }

  .label {
    flex: 0 0;
    order: 2;
    float: left;
    padding-left: 16px;
    font-size: 16px;
    font-weight: 600;
  }

  .action {
    flex: 0 0 auto;
    order: 3;
    float: right;
    text-align: right;
    padding-right: 16px;
    i {
      font-size: 32px;
      line-height: 56px;
    }
  }

  .preloader-wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    padding-top: 6px;
  }

  
}

$sass-toolbar-height: 88px;


._collection-management-toolbar {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    color: rgba(0,0,0,0.8);
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: $sass-toolbar-height;
    line-height: $sass-toolbar-height;
    z-index: 1;
    transition: all $motion-duration-short-3 cubic-bezier(0.3, 0, 0.8, 0.15);

    .fill-remaining-space {
        order: 1;
        flex: 1 0 auto;
    }

    .search-wrapper {
//        flex: 1 0 auto;
        flex: 0 0 480px;
        order: 2;
        float: left;
        padding-top: 16px;
        user-select: none;

    }

    .action-items {
        flex: 0 0 auto;
        order: 3;
        float: right;
        padding-left: 36px;
        padding-right: 36px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding-bottom: 28px;

        .item {
            width: 40px;
            height: $sass-toolbar-height;
            line-height: $sass-toolbar-height;
            padding-left: 16px;
            display: inline-block;
            text-transform: uppercase;
            color: #ffffff;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
        }
    }

    .action-button-wrapper {
        height: $sass-toolbar-height;
        flex: 0 0 auto;
        order: 5;
        float: right;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        padding-top: 16px;
    }
}
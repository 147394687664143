$table-striped-color: rgba(0,0,0,0.2);
$table-border-color:  rgba(0,0,0,0.2);

// Tables
table, th, td {
   border: none;
}

table {
  width:100%;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;

  &.striped {
    tr {
      border-bottom: none;
    }

    > tbody {
      > tr:nth-child(odd) {
        background-color: $table-striped-color;
      }

      > tr > td {
        border-radius: 0;
      }
    }
  }

  &.highlight > tbody > tr {
    transition: background-color .25s ease;
    &:hover {
      background-color: $table-striped-color;
    }
  }

  &.centered {
    thead tr th, tbody tr td {
      text-align: center;
    }
  }
}

tr {
  border-bottom: 1px solid $table-border-color;
}

td, th{
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
  
$vollkorn-font-path: "/assets/fonts/vollkorn/" !default;


@font-face {
    font-family: 'Vollkorn';
    src: url("#{$vollkorn-font-path}Vollkorn-VariableFont_wght.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Vollkorn Italic';
    src: url("#{$vollkorn-font-path}Vollkorn-Italic-VariableFont_wght.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}


$golden-ratio: 1.618em;


$horizontal-spacing-4: 4px;
$horizontal-spacing-8: 8px;
$horizontal-spacing-16: 16px;
$horizontal-spacing-24: 24px;
$horizontal-spacing-32: 32px;
$horizontal-spacing-48: 48px;
$horizontal-spacing-64: 64px;
$horizontal-spacing-80: 80px;


/*
._application-container {
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
    @extend .background-color-transition-350;
	color:#708090
}
*/


.scrollable-div-container {
	width: 100%;
	height: 100%; 
	padding-left:  0%;
	padding-right: 0%;
	padding-bottom: 96px;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: block;
}


.scrollable-div-container::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}



.test-container {
	width: 100%;
	min-height: 500px; 
	max-height: 500px; 
	overflow: hidden;
}

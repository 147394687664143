$robotomono-font-path: "/assets/fonts/robotomono/" !default;

/****************************************************************
ROBOTO
****************************************************************/
@font-face {
    font-family: 'Roboto Mono', monospace;
    src: url("#{$robotomono-font-path}robotomono-regular-webfont.eot?#iefix") format('embedded-opentype'),
        url("#{$robotomono-font-path}robotomono-regular-webfont.woff2") format("woff2"),
        url("#{$robotomono-font-path}robotomono-regular-webfont.woff") format("woff"),
        url("#{$robotomono-font-path}robotomono-regular-webfont.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
}



._badge {
    min-width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    border-radius: 9px;
    background-color: #FF9494;
    color: #FFFFFF;
    @extend ._label-small;

    //get rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;

    &.small {
        min-width: 6px;
        max-width: 6px;
        padding: 0;
        height: 6px;
        border-radius: 50%;
        overflow: hidden;
        padding: 0;
    }
}
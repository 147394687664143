$exo2-font-path: "/assets/fonts/exo_2/" !default;


@font-face {
    font-family: 'Exo2';
    src: url("#{$exo2-font-path}Exo2-VariableFont_wght.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Exo2 Italic';
    src: url("#{$exo2-font-path}Exo2-Italic-VariableFont_wght.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}



._technical-document-page {
    padding-top: 72px;
    padding-left: 8.125%;
    padding-right: 8.125%;

    .heading {
        @extend ._display-large;
        margin-bottom: 56px;
    }

    .subheading {
        @extend ._headline-large;
        margin-bottom: 56px;
    }


    .body {

    }

}
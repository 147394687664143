$quicksand-font-path: "/assets/fonts/quicksand/" !default;


/*** Aileron**/
@font-face {
    font-family: 'Quicksand';
    src: url("#{$quicksand-font-path}Quicksand-VariableFont_wght.ttf") format("truetype");
    //font-weight: normal;
    font-style: normal;
}

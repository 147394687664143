
._text-input-filled-container {
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  border-top-left-radius: $radius-extra-small;
  border-top-right-radius: $radius-extra-small;
  font-weight: 500;
  position: relative;
  margin-bottom: 32px;

  .leading-icon {
    position: absolute;
    left: 16px;
    top: 16px;
    i {
        font-size: 28px;
        line-height: 60px;
    }
  }

  .input-field {
    width: 100%;
    height: 56px;
    padding-left: 14px;
    padding-right: 12px;
    overflow: hidden;
    @extend ._body-large;
    box-sizing: border-box;
    vertical-align:top;
    background-color: rgba(0,0,0,0);
    border: 0;

    &:focus {
      outline: none;
    }

    input::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
  }

  .label {
    position: absolute;
    left: 12px;
    top: 20px;
    @extend ._body-large;
  }

  .trailing-icon-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    text-align: center;
    width: 56px;
    height: 56px;
    i {
      font-size: 20px;
      line-height: 58px;
    }

  }

  .text-input-supporting-text {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    @extend ._body-small;
    text-align: left;
    position: absolute;
    bottom: -20px;
    left: 12px;
  }

  &.active-no-icon {
    .label {
      top: 6px;
      left: 18px;
      @extend ._body-small;
    }
    .input-field {
      line-height: 64px;  
    }
  }


  &.active-has-icon {
    border-bottom: 1px solid #000000;
    .label {
      top: 8px;
      left: 14px;
      @extend ._body-small;
    }
    .input-field {
      padding-top: 20px;
    }
  }
}







/*
._text-input-filled-container {
  height: 56px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
  border-top-left-radius: $radius-extra-small;
  border-top-right-radius: $radius-extra-small;
  font-weight: 500;
  position: relative;
  margin-bottom: 32px;

  .leading-icon {
    padding-left: 16px;
    box-sizing: border-box;
    overflow: hidden;
    flex: 0 0 40px;
    order: 1;
    
    i {
        font-size: 28px;
        line-height: 60px;
    }
  }

  .input-field {
    order: 2;
    flex: 1 0 auto;
    height: 56px;
    padding-left: 14px;
    padding-right: 12px;
    overflow: hidden;
    @extend ._body-large;
    box-sizing: border-box;
    vertical-align:top;
    background-color: rgba(0,0,0,0);
    border: 0;

    &:focus {
      outline: none;
    }
  }

  .label {
    position: absolute;
    left: 12px;
    top: 20px;
    @extend ._body-large;
  }

  .trailing-icon-wrapper {
    order: 3;
    flex: 0 0 32px;
    margin-right: 16px;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    z-index: 1000;
    i {
      font-size: 20px;
      line-height: 58px;
    }

  }

  .text-input-supporting-text {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    @extend ._body-small;
    text-align: left;
    position: absolute;
    bottom: -20px;
    left: 12px;
  }

  &.active-no-icon {
    .label {
      top: 6px;
      left: 18px;
      @extend ._body-small;
    }
    .input-field {
      line-height: 64px;  
    }
  }


  &.active-has-icon {
    border-bottom: 1px solid #000000;
    .label {
      top: 8px;
      left: 14px;
      @extend ._body-small;
    }
    .input-field {
      padding-top: 20px;
    }
  }

}
*/


 
._modal-container {
    width: 100%;
    height: 100%;
    border-radius: $radius-medium;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 12px;
    margin-right: 8px;
    position:relative;
    transition: all $motion-duration-medium-2 cubic-bezier(0.2, 0.0, 0, 1.0);

    &.fullscreen {
        position:fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
        width: 100%;
        height: 100%;
    }
}


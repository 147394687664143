
._saas-page-header {
    width: 100%;
    height: auto;
    overflow: hidden;
    box-sizing: border-box;


    .heading {
        @extend ._headline-large;
    }

    .subheading {
        @extend ._title-medium;
    }

}
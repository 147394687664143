
._airline01-trip-details-card {
  position: relative;
  box-sizing: border-box;
  vertical-align:top;
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  cursor:pointer;
  overflow: hidden;
  transition: all $motion-duration-short-3 cubic-bezier(0.2, 0.0, 0, 1.0);

  .media {
      width: 100%;
      height: 220px;
      overflow: hidden;
      box-sizing: border-box;
      position: relative;
      img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          box-sizing: border-box;
      }

      .heading {
        position: absolute;
        bottom: 16px;
        left: 16px;
        @extend ._headline-small;
        font-weight: 600;
        color: #FFFFFF;
      }
  }

  ._card-content {
    width: 100%;
    padding-bottom: 16px;
    box-sizing: border-box;
    overflow: hidden;
    user-select: none;
    -webkit-user-select: none;
    padding-left: 16px;
    padding-right: 16px;

    .flight-info {
        width:        100%;
        height:       96px; 
        margin-bottom: 32px;       
    }

    .divider {
        width: 100%;
        height: 1px;
        margin-bottom: 16px;  
        opacity: 0.3;     
    }
  }
}



.flight-summary-tile {
    width:        100%;
    height:       96px;
    padding-top:  8px;
        
    .flight-info-line {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        -webkit-box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        box-sizing: border-box !important;
        overflow: hidden;
        font-size: 14px;
        font-weight: 600;
        opacity: 0.6;
        letter-spacing: 0.1;
        margin-bottom: 6px;

        .departure {
          flex: 0 1 auto;
        }
    
        .fillspace {
          flex: 1 0 auto;
        }
    
        .flighttime {
          flex: 1 0 auto;
          text-align: center;
          box-sizing: border-box !important;

          i {
            font-size: 16px;
            display: inline-block;
          }
        }

        .arrival {
          flex: 0 1 auto;
          text-align: right;
        }        
    }

    
    .flight-times {
        width:        100%;
        height:       32px;
        //line-height:  32px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        -webkit-box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        box-sizing: border-box !important;
        overflow: hidden;
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 12px;
    
        .origin {
          flex: 0 0 72px;
          padding-right: 16px;
        }
    
        .fillspace {
          flex: 1 0 auto;
        }
    
        .progress {
          flex: 1 0 auto;
          height: 2px;
          background-color: rgba(0,0,0,0.15);
          position: relative;
    
          &.noline {
            background-color: rgba(0,0,0,0);
          }
    
          &.dark {
            background-color: rgba(255,255,255,0.2);
          }
    
          i {
            -webkit-transform: rotate(90deg);
            position: absolute;
            top: -14px;
            left: 10%;
          }
    
        }
    
        .destination {
          flex: 0 0 72px;
          text-align: right;
          padding-left: 16px;
        }
    }


    .destinations {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        -webkit-box-sizing: border-box !important;
        -moz-box-sizing: border-box !important;
        box-sizing: border-box !important;
        overflow: hidden;
        font-size: 16px;
        opacity: 0.8;
        letter-spacing: 0.1;
        margin-bottom: 8px;

        .departure {
          flex: 0 0 200px;
        }
    
        .fillspace {
          flex: 1 0 auto;
        }
    
        .flighttime {
          flex: 1 0 auto;
          position: relative;
          text-align: center;

          i {
            font-size: 16px;
            display: inline-block;
          }
        }

        .arrival {
          flex: 0 0 72px;
          text-align: right;
        }        
    }
    

}


._trip-timeline-tile {
    @extend .list-tile-item;
    height: 56px;
    font-family: Arial, Helvetica, sans-serif;
    //padding-left: 16px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 0;

    .progress-point {
        order: 1;
        flex: 0 0 40px;
        overflow: hidden;
        display: inline-block;
        box-sizing: border-box;
        color: #000000;
        box-sizing: border-box;
        padding-top: 10px;
        position: relative;

        .circle {
            height: 16px;
            width: 16px;
            border-radius: 50%;
            border: 2px solid #000000;
        }

        .initial {
            position: absolute;
            left: 9px;
            width: 2px;
            height: 66px
        }

        .final {
            position: absolute;
            top: 0;
            left: 9px;
            width: 2px;
            height: 10px
        }

    }

    .content {
      order: 2;
      flex: 1 0;
      box-sizing: border-box;
      overflow: hidden;
      float: left;
      padding-top: 8px;

      .top-line {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 4px;
        box-sizing: border-box;

        .time {
            width: 64px;
            display: inline-block;
        }
        .info {
            display: inline-block;
        }
      }
  
      .bottom-line {
        font-size: 16px;
        font-weight: 400;
        opacity: 0.8;

        .time {
            width: 64px;
            display: inline-block;
        }
        .info {
            display: inline-block;
        }

      }
    }
  
}



._airline-info-tile {
    @extend .list-tile-item;
    height: 72px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 0;
  
    .progress-point {
        order: 1;
        flex: 0 0 40px;
        overflow: hidden;
        display: inline-block;
        box-sizing: border-box;
        color: #000000;
        box-sizing: border-box;
        padding-top: 10px;
        position: relative;

        .line {
            position: absolute;
            top: 0;
            left: 9px;
            width: 2px;
            height: 72px;
        }

    }

  
  
    .circle-wrapper {
      order: 2;
      flex: 0 0 auto;
      padding-top: 16px;
      padding-left: 6px;
      overflow: hidden;
      display: inline-block;
      box-sizing: border-box;
  
      .circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 16px;
        line-height: 40px;
        box-sizing: border-box;
        text-align: center;
  
        i {
          font-size: 18px;
          line-height: inherit;
        }
  
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
      }
    }
  
  
    .content {
      order: 3;
      flex: 1 0;
      height: 72px;
      box-sizing: border-box;
      overflow: hidden;
      float: left;
  
      .top-line {
        font-size: 16px;
        color: #000000;
        line-height: 48px;
        font-weight: 600;
      }
  
      .bottom-line {
        font-size: 16px;
        color: #000000;
        line-height: 0;
        vertical-align: top;
        font-weight: 400;
        opacity: 0.8;
      }
    }
  
  }
  


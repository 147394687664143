
._horizontal-hotel-card {
    position: relative;
    box-sizing: border-box;
    vertical-align:top;
    height: 100%;
    width: 144px;
    margin-right: 16px;
    -webkit-tap-highlight-color: transparent;
    cursor:pointer;
     
    overflow: hidden;
    scroll-snap-align: center;
    transition: all $motion-duration-short-3 cubic-bezier(0.2, 0.0, 0, 1.0);

    .media {
        width: 100%;
        height: 96px;
        border-radius: $radius-medium;
        overflow: hidden;
        box-sizing: border-box;
        img {
            width: 100%;
            height: 96px;
            object-fit: cover;
            border-radius: $radius-medium;
            overflow: hidden;
            box-sizing: border-box;
        }
    }

    ._card-content {
        width: 100%;
        padding-bottom: 16px;
        box-sizing: border-box;
        overflow: hidden;
        user-select: none;
        -webkit-user-select: none;

        ._review-ratings {
            width: 100%;
            height: 32px;
            padding-top: 8px;
            .score {
                width: 40px;
                box-sizing: border-box;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                height: 18px;
                line-height: 20px;
                border-radius: 9px;
                margin-right: 8px;
                color: #FFFFFF;
                display: inline-block;
            }
            .stars {
                display: inline-block;
            }
        }

        .title {
           @extend ._title-small;
           font-weight: 500;
           width: 100%;
        }
    }


    &:hover {
        transform: scale(1.02);
        ._card-content { 
            .title {
                font-weight: 600;
             }    
        }

    }

    &:first-child {
        margin-left: 0;
    }
}




._horizontal-hotel-card-row {
    height: 100%;
    padding-top: 8px;
    box-sizing: border-box;

    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    overflow-x: scroll;
    scroll-padding-left: 16px;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        height: 0px;
        background: transparent; 
    }
  
}
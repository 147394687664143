._stars-review {
    width: 100%;
    height: 32px;
    line-height: 32px;
	list-style: 	none;
	padding:		0;
	margin:			0;

    .item {
        display: inline-block;
        i {
          font-size: 22px;
        }
    }

    &.small {
        height: 18px;
        line-height: 18px;

        .item {
            i {
              font-size: 14px;
            }
        }
    }
}

///////////////////////////////////////////////////
// ONE LINE LIST
///////////////////////////////////////////////////

.three-item-list-tile-leading-image {
  @extend .list-tile-item;
  height: 88px;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 16px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;

  &.inset-divider {
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2) inset;
  }

  &.wide {
    .image-wrapper {
      padding-left: 0;
      padding-top: 16px;
      img {
        min-width: 100px;
        max-width: 100px;
        min-height: 56px;
        max-height: 56px;
      }
    }
    .content {
      flex: 0 0 244px;
    }
  }

  .image-wrapper {
    order: 1;
    flex: 0 0 auto;
    float: left;
    overflow: hidden;
    box-sizing: border-box;
    padding-left: 8px;
    padding-top: 16px;
    box-sizing: border-box;

    img {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      object-fit: fill;
      margin-right: 16px;
    }
  }

  .content {
    order: 1;
    flex: 1 0;
    height: 88px;
    box-sizing: border-box;
    overflow: hidden;
    float: left;
    padding-top: 14px;

    .heading {
      font-size: 16px;
      color: #000000;
      font-weight: 600;
      padding-bottom: 2px;
    }

    .secondary-line {
      font-size: 16px;
      color: #000000;
      line-height: 21px;
      word-wrap: normal;
      opacity: 0.7;
      font-weight: 400;
    }
  }

  .secondary-action {
    order: 2;
    flex: 0 0 auto;
    float: right;
    @extend .list-tile-secondary-action-88;
  }
}

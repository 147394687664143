$sifonn-font-path: "/assets/fonts/sifonn/" !default;

/*** Sifonn ***/
@font-face {
    font-family: 'Sifonn';
    src: url("#{$sifonn-font-path}sifonn_basic-webfont.ttf") format("truetype"),
         url('#{$sifonn-font-path}sifonn_basic-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$sifonn-font-path}sifonn_basic-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}


.sifonn {
  font-family: 'sifonnbasic';
}

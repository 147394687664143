._card {
    width: 100%;
    height: 100%;
    border-radius: $radius-medium;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 12px;
    margin-right: 8px;
    position:relative;
    transition: all $motion-duration-medium-2 cubic-bezier(0.2, 0.0, 0, 1.0);

    &.no-radius {
        border-radius: 0;
    }
}




._card-media {
    position: relative;
    box-sizing: border-box;
    vertical-align:top;
    height: 100%;
    border-radius: $radius-medium;
    overflow: hidden;

    .media {
        width: 100%;
        height: 144px;
        border-radius: $radius-medium;
        overflow: hidden;
        box-sizing: border-box;
        img {
            width: 100%;
            height: 144px;
            object-fit: cover;
            border-radius: $radius-medium;
            overflow: hidden;
            box-sizing: border-box;
            }
    }

    ._card-content {
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
        box-sizing: border-box;
        overflow: hidden;

        .headline {
            @extend ._headline-small;
            font-weight: 600;
        }
        .subheading {
            @extend ._title-medium;
           // line-height: 20px;
           margin-bottom: 2px;
           font-weight: 600;
           line-height: 32px;
        }

        .body {
            @extend ._body-medium;
            line-height: 22px;
            text-align: justify;
        }
    }

    ._card-actions {
        width: 100%;
        height: 56px;
        padding-top: 16px;
        padding-left: 16px;
        padding-bottom: 16px;
        box-sizing: border-box;
        overflow: hidden;

        .button {
            display: inline-block;
            margin-right: 12px;
        }

    }

}

$rubik-font-path: "/assets/fonts/rubik/" !default;

/*** Poppins ***/
@font-face {
    font-family: 'Rubik Light';
    src: url("#{$rubik-font-path}rubik-light-webfont.ttf") format("truetype"),
         url('#{$rubik-font-path}rubik-light-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$rubik-font-path}rubik-light-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik Medium';
    src: url("#{$rubik-font-path}rubik-medium-webfont.ttf") format("truetype"),
         url('#{$rubik-font-path}rubik-medium-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$rubik-font-path}rubik-medium-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik Regular';
    src: url("#{$rubik-font-path}rubik-regular-webfont.ttf") format("truetype"),
         url('#{$rubik-font-path}rubik-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$rubik-font-path}rubik-regular-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}



.rubik-regular {
  font-family: 'poppinsregular';
}

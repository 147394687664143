
._fullscreen-toast {
   position: relative;
   width: 100%;
   height: 100vh;

  .toast-block {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    color: #FFFFFF;
    text-align: center;

    .icon {
      font-size: 40px;
      margin-bottom: 6px;
      animation: zoomInIcon $motion-duration-long-1 cubic-bezier(0.24, 0, 0.38, 1);
    }
    .message {
      @extend ._title-large;
    }
  }

}


@keyframes zoomInIcon {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(2.4);
  }
  100% {
    transform: scale(1);
  }

}
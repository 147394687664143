._color-swatch-card-01 {
	position: relative;
	background-color: #ffffff;
	border-radius: 4px;
	width: 100%;

	&.delete-mode {
		opacity: 0.8;
	}

	.swatch-color {
		position: relative;
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
		padding-bottom: 0;
		width: 100%;
		height: 120px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.swatch-content {
		padding-left: 8px;
		padding-right: 8px;
		height: 36px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		line-height: 36px;

		//title of the color  eg. "Radical Red"
		.swatch-title {
			//font-weight: 600;
			color: #000000;
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			@extend ._title-medium;
		}
	}

	.delete-mode-icon {
		position: absolute;
		top: -8px;
		right: -8px;
		border-radius: 16px;
		width: 32px;
		height: 32px;
		z-index: 1;
		background: rgba(0, 0, 0, 0.6);
		padding: 4px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		@extend .vibrating-color-card;

		i {
			font-size: 24px;
			line-height: 24px;
			color: #ffffff;
		}
	}
}

._gradient-swatch-card {
	position: relative;
	background-color: #ffffff;
	border-radius: 2px;
	width: 200px;
	height: 230px;
	overflow: hidden;
	//	@extend .z-depth-1;

	.swatch-color {
		position: relative;
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
		padding-bottom: 0;
		width: 100%;
		height: 160px;
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		box-sizing: border-box;

		.layer {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 160px;
		}
	}

	.swatch-content {
		padding-left: 8px;
		padding-right: 8px;
		padding-top: 8px;
		padding-bottom: 8px;

		//title of the color  eg. "Radical Red"
		.swatch-title {
			font-weight: 400;
			color: #000000;
			opacity: 0.87;
			margin-bottom: 8px;
			@extend ._title-medium;
		}

		// ??
		.swatch-color-bar {
			height: 40px;
			padding-top: 4px;

			list-style: none;
			padding: 0;
			margin: 0;
			overflow: hidden;

			.color-circle {
				margin-right: 8px;
				display: inline-block;
				position: relative;
				float: left;

				height: 24px;
				width: 24px;
				border-radius: 16px;

				&:firstchild {
					margin-left: 0;
				}
			}
		}
	}
}

.vibrating-color-card {
	animation: vibrating-color-card-effect 350ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
	animation-iteration-count: infinite;
}

@keyframes vibrating-color-card-effect {
	0% {
		transform: scale(1);
	}

	25% {
		transform: rotate(-0.2deg);
	}

	50% {
		transform: rotate(0deg);
	}

	75% {
		transform: rotate(-0.2deg);
	}

	100% {
		transform: rotate(0) scale(1);
	}
}

//////////////////////////////////////////////
// Colors and opacity can be gently transitioned.
//////////////////////////////////////////////
.color-transition-150 {
		-webkit-transition: color 150ms linear;
		transition: color 150ms linear;
}

.color-transition-350 {
		-webkit-transition: color 350ms linear;
		transition: color 350ms linear;
}

.color-transition-650 {
		-webkit-transition: color 650ms linear;
		transition: color 650ms linear;
}

.color-transition-1000 {
		-webkit-transition: color 1000ms linear;
		transition: color 1000ms linear;
}




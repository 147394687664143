._radar-circle {
  position: absolute;
  bottom: 0;
  height: 300px;
  width: 100%;
  z-index: 3;
  padding-top: 64px;
  box-sizing: border-box;

  .circle {
    width: 64px;
    height: 64px;
    margin: auto;
    overflow: visible;

    border-radius: 32px;
    background-color: #FFFFFF;
    position: relative;
    @extend .shadow-2;
  }


  .circle-radar-pulse {
    overflow: initial;
    z-index: 10;
  
    &.circle-radar-pulse::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: inherit;
      border-radius: inherit;
      transform: translateZ(0);
      transition: opacity .3s, transform .3s;
      animation: circle-radar-pulse-animation 1000ms ease-in infinite;
      z-index: -1;
    }
  }

}


@keyframes circle-radar-pulse-animation {
  0% {
    opacity: 0.4;
    transform: translateZ(0);
  }
  100% {
    opacity: 0;
    transform: scale(8) translateZ(0);
  }
}
$montserrat-font-path: "/assets/fonts/montserrat/" !default;


@font-face {
    font-family: "Montserrat Light";
    src: local(Montserrat Light), url('#{$montserrat-font-path}Montserrat-Light.ttf');
    src: url("#{$montserrat-font-path}Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
}
@font-face {
    font-family: "Montserrat Regular";
    src: local(Montserrat Regular), url('#{$montserrat-font-path}Montserrat-Regular.ttf');
    src: url("#{$montserrat-font-path}Montserrat-Regular.ttf") format("truetype");
    font-weight: 400; 
}
@font-face {
    font-family: "Montserrat Bold";
    src: local(Montserrat Bold), url('#{$montserrat-font-path}Montserrat-Bold.ttf');
    src: url("#{$montserrat-font-path}Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Montserrat Semi Bold";
    src: local(Montserrat Semi Bold), url('#{$montserrat-font-path}Montserrat-SemiBold.ttf');
    src: url("#{$montserrat-font-path}Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Montserrat Medium";
    src: local(Montserrat Medium), url('#{$montserrat-font-path}Montserrat-Medium.ttf');
    src: url("#{$montserrat-font-path}Montserrat-Medium.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Montserrat Hairline";
    src: local(Montserrat Hairline), url('#{$montserrat-font-path}Montserrat-Hairline.ttf');
    src: url("#{$montserrat-font-path}Montserrat-Hairline.ttf") format("truetype");
    font-weight: 100;
}

.montserrat {
  font-family: "Montserrat";
}

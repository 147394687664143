$font-size-scale: 20px;
$line-height-scale: 1.35;
$typescale-factor: 1.618;

body {
    //	font-size: 75%;
}

html > body {
    font-size: $font-size-scale;
    line-height: ($font-size-scale * $line-height-scale) px;
}

/**
*  Paragraph - with spacing rules
**/
.paragraph {
    margin-top: 0em;
    margin-bottom: 1.3333em;
}

.article {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    line-height: 1.3333em;
   // margin: auto;

    ul ol {
        margin-top: 0em;
        margin-bottom: 1.33333333em;        
    }
}

.h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 4.2222em;
    line-height: 1.26315789em;
    margin-top: 0.31578947em;
    margin-bottom: 0.63157894em;
    font-weight: 700;
}

.h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.61111111em;
    line-height: 1.0212766em;
    margin-top: 0.5106383em;
    margin-bottom: 0.5106383em;
    font-weight: 600;
}

.h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.61111111em;
    line-height: 1.65517241em;
    margin-top: 0.82758621em;
    margin-bottom: 0em;
    font-weight: 600;
}

.h4 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    line-height: 1.33333333em;
    margin-top: 1.33333333em;
    margin-bottom: 0em;
    font-weight: 600;
}

ul ul,
ol ol,
ul ol,
ol ul {
    margin-top: 0em;
    margin-bottom: 0em;
}

a,
b,
i,
strong,
em,
small,
code {
    line-height: 0;
}

sub,
sup {
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}

/*
https://24ways.org/2006/compose-to-a-vertical-rhythm/
*/

.std-button {
  border: none;
  border-radius: 4px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
 // background-color: #00cccc;
  color: #ffffff;
  white-space: nowrap;
  font-family: Arial, Helvetica, sans-serif;

  //get rid of text selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  box-sizing: border-box;

  // font
  vertical-align: top;
  font-size: 16px;
  font-weight: 500;

  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;

  cursor: pointer;
  position: relative;

  i {
    font-size: 32px;
    line-height: 36px;
    height: 36px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.fullwidth {
    width: 100%;
   // padding: 0;
    text-align: center;
  }

  .preloader-wrapper {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    padding-top: 6px;
  }

  &.large {
    height: 48px;
    line-height: 48px;
    padding: 0 3rem;
    font-size: 18px;

    i {
      font-size: 32px;
      line-height: 36px;
      height: 36px;
    }

    .preloader-wrapper {
      padding-top: 6px;
    }
  
  }


}




.std-button-large {
  border: none;
  border-radius: 4px;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  padding: 0 3rem;
 // background-color: #00cccc;
  color: #ffffff;
  white-space: nowrap;
  font-family: Arial, Helvetica, sans-serif;

  //get rid of text selection
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  box-sizing: border-box;

  // font
  vertical-align: top;
  font-size: 20px;

  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;

  cursor: pointer;
  position: relative;

  i {
    font-size: 32px;
    line-height: 36px;
    height: 36px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.fullwidth {
    width: 100%;
   // padding: 0;
    text-align: center;
  }

  .preloader-wrapper {
    text-align: center;
    padding-top: 2px;
   // margin: 0 auto;
  }

}

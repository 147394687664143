._search-bar {
    width: 100%;
    height: 56px;
    border-radius: $radius-extra-large;
    box-sizing: border-box;
    position: relative;
    line-height: 56px;
    border: none;

    display:          flex;
    flex-wrap:        nowrap;
    flex-direction:   row;
    align-items:      stretch;
  
    transition: all $motion-duration-medium-3 cubic-bezier(0.2, 0.0, 0, 1.0);

    &.container-mode {
        border-radius: 0;
        .container {
           // min-height: 300px;
          //  border-top: 1px solid #000000;
            padding-left: 8px;
            padding-right: 8px;
            height: auto;
            overflow: auto;

            .divider {
                height: 1px;
                width: 100%;
                opacity: 0.5;
            }
    
        }
    }

    .container {
        position: absolute;
        top: 56px;
        left: 0;
        width: 100%;
        height: 0;
        z-index: 1000;
        overflow-x: hidden;
        overflow-y: hidden;
        box-sizing: border-box;
        transition: height $motion-duration-long-3 cubic-bezier(0.2, 0.0, 0, 1.0);

        .divider {
            height: 0;
            width: 100%;
        }
    }


    .leading-icon {
        padding-left: 16px;
      //  padding-right: 24px;
        box-sizing: border-box;
        overflow: hidden;

        flex: 0 0 56px;
        order: 1;
    
        i {
            font-size: 28px;
            line-height: 60px;
        }
    }


    .input-field-wrapper {
        order: 2;
        flex: 1 0 auto;
        height: 56px;
        overflow: hidden;
    }

    .trailing-icon-wrapper {
        order: 3;
        flex: 0 0 auto;

        .item {
            width: 24px;
            margin-left: 16px;
            margin-right: 16px;
            box-sizing: border-box;
            overflow: hidden;
            cursor: pointer;
            text-align: center;
            display: inline-block;
            i {
                font-size: 28px;
                line-height: 60px;
            }
        }

        .avatar {
            
        }

    }

    .input-field {
        width: 100%;
        height: 56px;
        overflow: hidden;
        background-color: rgba(0,0,0,0);
        border: none;
        @extend ._body-large;

        &:focus {
            outline: none;
        }
    }   
}

$lato-font-path: "/assets/fonts/lato/" !default;


@font-face {
    font-family: "Lato Black";
    src: local(Lato Black), url('#{$lato-font-path}Lato-Black.ttf');
    src: url("#{$lato-font-path}Lato-Black.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "Lato Regular";
    src: local(Lato Regular), url('#{$lato-font-path}Lato-Regular.ttf');
    src: url("#{$lato-font-path}Lato-Regular.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
  font-family: "Lato Medium";
  src: local(Lato Regular), url('#{$lato-font-path}Lato-Medium.ttf');
  src: url("#{$lato-font-path}Lato-Medium.ttf") format("truetype");
  font-weight: 500;
}


.lato-black {
  font-family: "Lato Black";
}

.lato-regular {
  font-family: "Lato Regular";
}

._features-section {
    width: 100%;
    padding-top: 64px;
    padding-bottom: 64px;
}

._icon-feature-block {
    width: 100%;
    height: 220px;
    padding-top: 16px;
    padding-left: 64px;
    padding-right: 64px;
    border-radius: $radius-large;
    box-sizing: border-box;

    .icon {
        text-align: center;
        font-size: 32px;
        margin-bottom: 12px;
    }

    .heading {
        text-align: center;
        @extend ._title-large;
        margin-bottom: 12px;
    }

    .body {
        @extend ._body-large;
        text-align: justify;
    }
}


._features-flex-row {
    display: flex;
    flex-direction: column;
    padding: 40px;
    align-items: center;
}


.htc-swatch {
    width: 40px;   
   }
   
   
   
   .color-tonal-box {
       display: inline-block;
       width: 48px;
       height: 56px;
   
       line-height: 56px;
       text-align: center;
       color: #FFFFFF;
       font-weight: 500;
       font-size: 14px;
       font-family: Arial, Helvetica, sans-serif;
   }
   
   .surface-swatch {
       display: inline-block;
       position: relative;
       width: 250px;
       height: 112px;
       background-color: #f2f2f2;
       color: #000000;
       padding: 8px;
       font-size: 16px;
       font-weight: 500;
       font-family: Arial, Helvetica, sans-serif;
   
       &.small {
           width: 150px;
       }
   
       &.flat {
           width: 187.5px;
           height: 32px;
       }
   
       .label {
           position: absolute;
           top: 16px;
           left: 16px;
       }
   
       .code {
           position: absolute;
           bottom: 16px;
           right: 16px;
       }
   
   }
   
///////////////////////////////////////////////////
// APPBAR using Flex
///////////////////////////////////////////////////
$web-appbar-top-height: 64px;

._web-appbar-w-search-top {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    color: rgba(0,0,0,0.8);
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: $web-appbar-top-height;
    line-height: $web-appbar-top-height;
    padding-left: 24px;
    padding-right: 24px;
    z-index: 1;
    transition: all $motion-duration-short-3 cubic-bezier(0.3, 0, 0.8, 0.15);


    .fill-remaining-space {
        order: 1;
        flex: 1 0 auto;
    }

    .search-wrapper {
//        flex: 1 0 auto;
        flex: 0 0 480px;
        order: 2;
        float: left;
        padding-top: 16px;
        user-select: none;

    }

    .action-items {
        flex: 0 0 auto;
        order: 3;
        float: right;
        padding-left: 36px;
        padding-right: 36px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: $web-appbar-top-height;
        line-height: $web-appbar-top-height;
        padding-top: 12px;
    
        .item {
            margin-left: 12px;
            display: inline-block;
            height: $web-appbar-top-height;
            line-height: $web-appbar-top-height;
        }


    }

    .avatar-wrapper {
        height: $web-appbar-top-height;
        flex: 0 0 auto;
        order: 5;
        float: right;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        padding-top: 16px;
        padding-right: 4px;
        z-index: 5;
    }
}


._landing-page01 {

    width: 100%;
    height: 100%;
    position: relative;
    padding: 64px;
    box-sizing: border-box;

    .hero-block {
        margin-top: 10%;
        width: 100%;
        height: 500px;
        position: relative;
            
        .text-block {
            width: 100%;
            height: 200px;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            
            .headline {
                @extend ._headline-large;
            }

            .description {
                @extend ._headline-small;
            }

        }

        .cta-button { 

        }

        .hero-image {

        }

    }


    .fab-container {
        position: fixed;
        bottom: 24px;
        right: 24px;
    }
}
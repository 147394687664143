.text-divider {
    --text-divider-gap: 1rem;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.1em;
    height: 56px;
  }
  
  .text-divider::before, .text-divider::after {
    content: '';
    height: 1px;
    background-color: silver;
    flex-grow: 1;
  }
  
  .text-divider::before {
    margin-right: var(--text-divider-gap);
  }
  
  .text-divider::after {
    margin-left: var(--text-divider-gap);
  }


._sp-home-screen {

    .hero-slide {
        width: 100%;
        height: 320px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 32px;
        background-color: rgba(0,0,0,0.9);

        img {
            width: 100%;
            min-height: 320px;
            max-height: 320px;
            object-fit: cover;
        }

        .tint {
            width: 100%;
            height: 320px;
            top: 0;
            left: 0;
            position: absolute;
            background-color: rgba(0,0,0,0.4);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;

            .cta-block {
                position: relative;
                top: 48%;
                margin: auto;
                .text {
                    width: 100%;
                    @extend ._headline-medium;
                    font-weight: 500;
                    color: #FFFFFF;
                    //text-shadow: 1px 1px rgba(0,0,0,0.5);
                    text-align: center;
                }
                .cta-wrapper {
                    width: 100%;
                    height: 96px;
                    padding-top: 16px;
                    padding-bottom: 24px;
                    text-align: center;
                }
    
            }
    
        }

    }

    .slide {
        width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin-bottom: 64px;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }

        &.lightened {
            background-color: rgba(255,255,255,0.1);
        }

        .heading {
            text-align: center;
            font-weight: 700;
            @extend ._headline-large;
            margin-bottom: 16px;
        }

        .content {
            margin-left: 44px;
            margin-right: 44px;
            text-align: justify;
            @extend ._title-large;
            line-height: 24px;
            font-weight: 500;
        }

        .cta-wrapper {
            width: 100%;
            height: 96px;
            padding-top: 24px;
            padding-bottom: 24px;
            text-align: center;
        }

    }


    ._testimonial_cards_slide {

    }

}
    


.slowscroll {
    transition: all 3000ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
}



.testimony-card {
    width: 95%;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 8.125%;
    padding-right: 8.125%;
    opacity: 0.85;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.1);
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    background-color: rgba(0,0,0,0.05);
    margin-bottom: 24px;

    .photo {
        text-align: center;  
        margin-bottom: 24px;      
        img {
            width: 50%;
            height: auto;
        }
    }

    .testimony {
        text-align: left;
        @extend ._title-large;
        font-weight: 400;
        font-style: italic;
        line-height: 28px;
        margin-bottom: 24px;
    }


    .name {
        text-align: left;
        @extend ._title-large;
        margin-bottom: 24px;
        font-weight: 500;
    }

    .description {
        text-align: left;
        @extend ._title-medium;
    }

}



.sp-cta-button {
    border: none;
    border-radius: 4px;
    display: inline-block;
    height: 44px;
    line-height: 44px;
    padding: 0 4rem;
    color: #ffffff;
    white-space: nowrap;
    font-family: Arial, Helvetica, sans-serif;
  
    //get rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    box-sizing: border-box;
  
    // font
    vertical-align: top;
    font-size: 22px;
    font-weight: 500;
  
    // Gets rid of tap active state
    -webkit-tap-highlight-color: transparent;
  
    cursor: pointer;
    position: relative;
  
  
  }

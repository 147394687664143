._icon-tile {

    @extend .grid-element;
    text-align: center;
    border-radius: 2px;
    box-sizing: 		border-box;
    width: 96px;
    height: 96px;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 32px;
    .icon-wrapper {
        text-align: center;
        margin-bottom: 8px;
        vertical-align: top;

        i {
          font-size: 32px;
        }
    }

    .label {
        width: 100%;
        text-align: center;
        @extend ._label-medium;
    }
}



$raleway-font-path: "/assets/fonts/raleway/" !default;

@font-face {
    font-family: 'Raleway Black';
    src: url("#{$raleway-font-path}Raleway-Black.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-black {
  font-family: 'Raleway Black';
}

@font-face {
    font-family: 'Raleway Black Italic';
    src: url("#{$raleway-font-path}Raleway-BlackItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-black-italic {
  font-family: 'Raleway Black Italic';
}



@font-face {
    font-family: 'Raleway Bold';
    src: url("#{$raleway-font-path}Raleway-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-bold {
  font-family: 'Raleway Bold';
}


@font-face {
    font-family: 'Raleway Bold Italic';
    src: url("#{$raleway-font-path}Raleway-BoldItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-bold-italic {
  font-family: 'Raleway Bold Italic';
}


@font-face {
    font-family: 'Raleway Extra Bold';
    src: url("#{$raleway-font-path}Raleway-ExtraBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-extra-bold {
  font-family: 'Raleway Extra Bold';
}


@font-face {
    font-family: 'Raleway Extra Bold Italic';
    src: url("#{$raleway-font-path}Raleway-ExtraBoldItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-extra-bold-italic {
  font-family: 'Raleway Extra Bold Italic';
}


@font-face {
    font-family: 'Raleway Extra Light';
    src: url("#{$raleway-font-path}Raleway-ExtraLight.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-extra-light {
  font-family: 'Raleway Extra Light';
}

@font-face {
    font-family: 'Raleway Extra Light Italic';
    src: url("#{$raleway-font-path}Raleway-ExtraLightItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-extra-light-italic {
  font-family: 'Raleway Extra Light Italic';
}


@font-face {
    font-family: 'Raleway Italic';
    src: url("#{$raleway-font-path}Raleway-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-italic {
  font-family: 'Raleway Italic';
}


@font-face {
    font-family: 'Raleway Light';
    src: url("#{$raleway-font-path}Raleway-Light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-light {
  font-family: 'Raleway Light';
}

@font-face {
    font-family: 'Raleway Light Italic';
    src: url("#{$raleway-font-path}Raleway-LightItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-light-italic {
  font-family: 'Raleway Light Italic';
}


@font-face {
    font-family: 'Raleway Medium';
    src: url("#{$raleway-font-path}Raleway-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-medium {
  font-family: 'Raleway Medium';
}


@font-face {
    font-family: 'Raleway Medium Italic';
    src: url("#{$raleway-font-path}Raleway-MediumItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-medium-italic {
  font-family: 'Raleway Medium Italic';
}


@font-face {
    font-family: 'Raleway Regular';
    src: url("#{$raleway-font-path}Raleway-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-regular {
  font-family: 'Raleway Regular';
}


@font-face {
    font-family: 'Raleway Semi Bold';
    src: url("#{$raleway-font-path}Raleway-SemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-semibold {
  font-family: 'Raleway Semi Bold';
}


@font-face {
    font-family: 'Raleway Semi Bold Italic';
    src: url("#{$raleway-font-path}Raleway-SemiBoldItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-semibold-italic {
  font-family: 'Raleway Semi Bold Italic';
}


@font-face {
    font-family: 'Raleway Thin';
    src: url("#{$raleway-font-path}Raleway-Thin.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-thin {
  font-family: 'Raleway Thin';
}


@font-face {
    font-family: 'Raleway Thin Italic';
    src: url("#{$raleway-font-path}Raleway-ThinItalic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.raleway-thin-italic {
  font-family: 'Raleway Thin Italic';
}

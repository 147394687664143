
$motion-duration-short-1: 50ms; 
$motion-duration-short-2: 100ms; 
$motion-duration-short-3: 150ms; 
$motion-duration-short-4: 200ms; 

$motion-duration-medium-1: 250ms; 
$motion-duration-medium-2: 300ms; 
$motion-duration-medium-3: 350ms; 
$motion-duration-medium-4: 400ms; 

$motion-duration-long-1: 450ms; 
$motion-duration-long-2: 500ms; 
$motion-duration-long-3: 550ms; 
$motion-duration-long-4: 600ms; 

$motion-duration-extra-long-1: 700ms; 
$motion-duration-extra-long-2: 800ms; 
$motion-duration-extra-long-3: 900ms; 
$motion-duration-extra-long-4: 1000ms; 

.all-motion-animation {
    transition: all $motion-duration-medium-3 cubic-bezier(0.2, 0.0, 0, 1.0);
}

.width-motion-animation {
    transition: width $motion-duration-medium-3 cubic-bezier(0.2, 0.0, 0, 1.0);
}

.padding-motion-animation {
    transition: padding $motion-duration-short-3 cubic-bezier(0.2, 0.0, 0, 1.0);
}

.margin-motion-animation {
    transition: margin $motion-duration-short-3 cubic-bezier(0.2, 0.0, 0, 1.0);
}

.background-change-animation {
    transition: background $motion-duration-medium-3 cubic-bezier(0.2, 0.0, 0, 1.0);
}

.opacity-motion-animation {
  transition: opacity $motion-duration-medium-3 cubic-bezier(0.2, 0.0, 0, 1.0);
}


.scale-0 {
    transform: scale(1);
 }
 
.scale-2 {
   transform: scale(1.001);
}




.fadeInLeft {
	animation: fadeInLeftAnim $motion-duration-long-2 cubic-bezier(0.24, 0, 0.38, 1);
}

@keyframes fadeInLeftAnim {
    0% {
       opacity: 0;
       transform: translateX(-20px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 } 

 .fadeInLeftBig {
	animation: fadeInLeftBigAnim $motion-duration-long-2 cubic-bezier(0.24, 0, 0.38, 1);
}

@keyframes fadeInLeftBigAnim {
    0% {
       opacity: 0;
       transform: translateX(-100px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 } 


.fadeInRight {
	animation: fadeInRightAnim $motion-duration-long-2 cubic-bezier(0.24, 0, 0.38, 1);
}

@keyframes fadeInRightAnim {
    0% {
       opacity: 0;
       transform: translateX(20px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 } 

 .fadeInRightBig {
	animation: fadeInRightBigAnim $motion-duration-long-2 cubic-bezier(0.24, 0, 0.38, 1);
}

@keyframes fadeInRightBigAnim {
    0% {
       opacity: 0;
       transform: translateX(100px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 } 




 .tiny-rubberband-effect {
	animation: tiny-rubberband-effect-anim 250ms cubic-bezier(0.24, 0, 0.38, 1);
}

@keyframes tiny-rubberband-effect-anim {
	from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.01, 0.99, 1);
  }

  40% {
    transform: scale3d(0.99, 1.01, 1);
  }

  50% {
    transform: scale3d(1.01, 0.99, 1);
  }

  65% {
    transform: scale3d(0.99, 1.01, 1);
  }

  75% {
    transform: scale3d(1.01, 0.99, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.medium-rubberband-effect {
	animation: medium-rubberband-effect-anim 350ms ease-in-out;
}

@keyframes medium-rubberband-effect-anim {
	from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.05, 0.95, 1);
  }

  40% {
    transform: scale3d(0.95, 1.05, 1);
  }

  50% {
    transform: scale3d(1.05, 0.95, 1);
  }

  65% {
    transform: scale3d(0.98, 1.02, 1);
  }

  75% {
    transform: scale3d(1.01, 0.99, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
//////////////////////////////////////////////////////////////////////
// Fontfaces
//////////////////////////////////////////////////////////////////////
@import './app/coreui/scss/fontfaces/alegreya';
@import './app/coreui/scss/fontfaces/bebasneue';
@import './app/coreui/scss/fontfaces/blinker';
@import './app/coreui/scss/fontfaces/bonveno';
@import './app/coreui/scss/fontfaces/comfortaa';
@import './app/coreui/scss/fontfaces/crimson-text';
@import './app/coreui/scss/fontfaces/dmsans';
@import './app/coreui/scss/fontfaces/ebgaramond';
@import './app/coreui/scss/fontfaces/exo2';
@import './app/coreui/scss/fontfaces/iceland';
@import './app/coreui/scss/fontfaces/inter';
@import './app/coreui/scss/fontfaces/josefin_sans';
@import './app/coreui/scss/fontfaces/lato';
@import './app/coreui/scss/fontfaces/leaguespartan';
@import './app/coreui/scss/fontfaces/librebaskerville';
@import './app/coreui/scss/fontfaces/manrope';
@import './app/coreui/scss/fontfaces/metropolis';
@import './app/coreui/scss/fontfaces/merriweather';
@import './app/coreui/scss/fontfaces/montserrat';
@import './app/coreui/scss/fontfaces/opensans';
@import './app/coreui/scss/fontfaces/playfair_display';
@import './app/coreui/scss/fontfaces/poppins';
@import './app/coreui/scss/fontfaces/questrial';
@import './app/coreui/scss/fontfaces/quicksand';
@import './app/coreui/scss/fontfaces/raleway';
@import './app/coreui/scss/fontfaces/roboto';
@import './app/coreui/scss/fontfaces/robotomono';
@import './app/coreui/scss/fontfaces/rubik';
@import './app/coreui/scss/fontfaces/sacramento';
@import './app/coreui/scss/fontfaces/sifonn';
@import './app/coreui/scss/fontfaces/sixcaps';
@import './app/coreui/scss/fontfaces/sourcesanspro';
@import './app/coreui/scss/fontfaces/vollkorn';


//@import './app/libraries/layout-templates/markdown/prism-material-light.css';
@import './app/libraries/layout-templates/markdown/prism-holi-theme.css';

//////////////////////////////////////////////////////////////////////
// Core Libraries
//////////////////////////////////////////////////////////////////////
@import './app/coreui/coreui.scss';


/** Layout Templates **/
@import './app/libraries/layout-templates/layout-templates.scss';


/* Dynamic Clouds Background */
@import './app/libraries/dynamic-clouds/dynamic-clouds.scss';

/* Color Swatches */
@import './app/libraries/color-swatches/colorswatches.scss';

/* Animated Tiles */
@import './app/libraries/animated-tiles/animatedtiles.scss';

/* Material Icons */
@import './app/libraries/material-icons/material-icons.scss';

/* Core UI Extensions */
@import './app/libraries/coreui-extensions/coreui-extensions.scss';

/* Blog Theme */
@import './app/libraries/themes/blog-theme/blog-theme.scss';

/* Tile Collections */
@import './app/libraries/tile-collections/tile-collections.scss';

/* Calendar UI */
@import './app/libraries/calendar-ui/calendar-ui.scss';

/* Card Examples */
@import './app/libraries/card-examples/card-examples.scss';

/* */
@import './app/libraries/people-ui/people-ui.scss';

/***************************************
Spiritans Web
***************************************/
@import './app/application/spiritans/spiritans.scss';


/***************************************
Loyalty App
***************************************/
@import './app/application/loyaltyapp/loyaltyapp.scss';



/***************************************
Peach Marketing App
***************************************/
@import './app/application/peachmarketing/peachmarketing.scss';

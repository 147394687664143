
._pm-navigation-drawer {
    width: 100%;
    height: 100vh;
    border-top-right-radius: $radius-large;
    border-bottom-right-radius: $radius-large;
    box-sizing: border-box;
   // padding-left: 8px;
   // padding-right: 8px;
    position: relative;
    overflow: hidden;

    .header {
        box-sizing: border-box;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        @extend ._title-small;

        .user {
            height: 56px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: stretch;
                    
            .circle-wrapper {
              order: 1;
              flex: 0 0 auto;
              padding-left: 8px;
              padding-top: 8px;
              overflow: hidden;
              display: inline-block;
              box-sizing: border-box;
          
              .circle {
                width: 44px;
                height: 44px;
                border-radius: 50%;
                margin-right: 16px;
                line-height: 44px;
                box-sizing: border-box;
                text-align: center;
          
                i {
                  font-size: 18px;
                  line-height: inherit;
                }
          
                img {
                  width: 44px;
                  height: 44px;
                  border-radius: 50%;
                }
              }
            }
          
            .content {
              order: 2;
              flex: 1 0;
              height: 56px;
              box-sizing: border-box;
              overflow: hidden;
              @extend ._title-medium;    
              font-weight: 600;  
              .primary-text {
                color: #FFFFFF;
                line-height: 56px;
                vertical-align: top;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          
        }


    }

    .menu-control {
        height: 56px;
        width: 100%;
        padding-left: 16px;
        line-height: 56px;
        color: #000000;
      
        i {
        font-size: 20px;
        line-height: inherit;
        margin-right: 28px;
        }      
    }


    .menu-container {
        list-style: none;
        box-sizing: border-box;
        transform: translateZ(0);
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 8px;
        padding-bottom: 16px;
        margin: 0;
    }
    

    .action {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 24px;
        box-sizing: border-box
    }
}


._sp-sn-menu-item {
    @extend .list-tile-item;
    height: 48px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    padding-left: 16px;
    border-radius: 24px;
    //get rid of text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  
    .label {
      order: 1;
      flex: 1 0;
      color: #000000;
      line-height: 48px;
      display: inline-block;
      vertical-align: top;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @extend ._title-large;
    }
  
    .secondary-action {
      order: 2;
      flex: 0 0 auto;
      @extend .list-tile-secondary-action-48;
    }
}

$librebaskerville-font-path: "/assets/fonts/librebaskerville" !default;

@font-face {
    font-family: "Libre Baskerville Bold";
    src: local(Lato Black), url('#{$librebaskerville-font-path}/LibreBaskerville-Bold.ttf');
    src: url("#{$librebaskerville-font-path}/LibreBaskerville-Bold.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
  font-family: "Libre Baskerville Italic";
    src: local(Lato Regular), url('#{$librebaskerville-font-path}/LibreBaskerville-Bold.ttf');
    src: url("#{$librebaskerville-font-path}/LibreBaskerville-Bold.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
  font-family: "Libre Baskerville Regular";
    src: local(Lato Regular), url('#{$librebaskerville-font-path}/LibreBaskerville-Regular.ttf');
    src: url("#{$librebaskerville-font-path}/LibreBaskerville-Regular.ttf") format("truetype");
    font-weight: 400;
}

$poppins-font-path: "/assets/fonts/poppins/" !default;

/*** Poppins ***/
@font-face {
    font-family: 'Poppins Regular';
    src: url("#{$poppins-font-path}poppins-regular-webfont.ttf") format("truetype"),
         url('#{$poppins-font-path}poppins-regular-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$poppins-font-path}poppins-regular-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins Medium';
    src: url("#{$poppins-font-path}poppins-medium-webfont.ttf") format("truetype"),
         url('#{$poppins-font-path}poppins-medium-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$poppins-font-path}poppins-medium-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins Light';
    src: url("#{$poppins-font-path}poppins-light-webfont.ttf") format("truetype"),
         url('#{$poppins-font-path}poppins-light-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$poppins-font-path}poppins-light-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins Semi Bold';
    src: url("#{$poppins-font-path}poppins-semibold-webfont.ttf") format("truetype"),
         url('#{$poppins-font-path}poppins-semibold-webfont.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$poppins-font-path}poppins-semibold-webfont.woff') format('woff'); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
}


._morning-sunrise-goodmorning-wrapper {
  width:        100%;
  height:       100%;


  .introduction-text {
    text-align:   center;
    margin-bottom:  12%;
    -webkit-transition: all 350ms ease-in-out;
    transform:          translateZ(0);
    @extend ._headline-medium;
    color: #FFFFFF;

    &.initial {
      margin-top:   40%;
      color:        #FFFFFF;
      transform:    translateZ(0);
    }

    &.secondary {
      margin-top:   0;
      color:        #FFFFFF;
      opacity:      1;
      transform:    translateZ(0);
    }

  }

  .icons-wrapper {
    -webkit-transition: all 750ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
    transform:        translateZ(0);
  }


}


.good-morning-label {
  position: fixed;
  width:    100%;
  top:      128px;
  left:     0;
  color:    #FFFFFF;
  -webkit-transition: all 750ms cubic-bezier(0.53, 0.01, 0.36, 1.63);
  

  .text {
    text-align: center;
  }
}


.good-morning-icon {
  width:      100%;
  height:     112px;
  text-align: center;
  transform:          translateZ(0);

  .circle {
    width:            80px;
    height:           80px;
    border-radius:    40px;
    background-color: #FFFFFF;
    margin:           0 auto;
    padding:              8px;
    -webkit-box-sizing:   border-box;
    overflow:             hidden;

    img {
      width:          56px;
      height:         56px;
      //object-fit:     fill;
      opacity:        0.65;
    }

  }



  .label {
    margin-top: 16px;
    color:      #FFFFFF;    
  }

}
